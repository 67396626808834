@import "../../styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    &:hover {
        .control {
            visibility: visible;
            opacity: 1; } } }

.preview {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    & > img {
        width: 100%; } }

.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: $neutrals3
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all .2s;
    @include dark {
 } }        //background: $neutrals8

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }

.button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%); }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 0;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

.line {
    display: flex;
    align-items: flex-start;
    &:first-child {
        margin-bottom: 12px; }
    &:nth-child(2) {
        margin-top: auto; } }

.title {
    margin-right: auto;
    padding-top: 1px;
    @include body-bold-2; }

.price {
    flex-shrink: 0;
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $green;
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: $green; }

.users {
    display: flex;
    margin-right: auto; }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $neutrals8;
    @include dark {
        border-color: $neutrals2; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -8px; } }

.counter {
    @include dark {
        color: $neutrals6; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -2px 4px 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }
