.flip-box {
background-color: transparent;
width: 200px;
height: 100px;
// / border: 1px solid #f1f1f1; /
perspective: 1000px;
}

.flip-box-inner {
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.flip-box-front {
/* background-color: #bbb;
color: black; */
}

.flip-box-back {
border-radius: 5px;
// / background-color: #003b46; /
color: white;
transform: rotateY(180deg);
}

.display_loader {
position: fixed;
top: -500px;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(20, 20, 20, 0.9);
z-index: 1200;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-transform: scale(0);
-ms-transform: scale(0);
transform: scale(0);
transition-duration: 0.4s;
-webkit-transition-duration: 0.4s;
-moz-transition-duration: 0.4s;
-o-transition-duration: 0.4s;
}

.display_loader.show {
-webkit-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1);
top: 0;
}

.loader {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: auto;
display: -webkit-inline-box;
display: -ms-inline-flexbox;
display: inline-flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
width: 90px;
height: 90px;
-webkit-transform-style: preserve-3d;
transform-style: preserve-3d;
-webkit-transform: rotateX(45deg) rotate(45deg);
transform: rotateX(45deg) rotate(45deg);
}


.box {
position: absolute;
top: 0;
left: 0;
margin-left: 30%;
margin-top: 2%;
width: 30px;
height: 30px;
background: #737373;
-webkit-box-shadow: 112.5px 112.5px 20px #000;
box-shadow: 112.5px 112.5px 20px #000;
-webkit-animation: move 1.5s ease-in-out infinite both;
animation: move 1.5s ease-in-out infinite both;
-webkit-transform-style: preserve-3d;
transform-style: preserve-3d;
}

.box:nth-child(1) {
-webkit-animation-delay: -0.75s;
animation-delay: -0.75s;
}

.box:nth-child(2) {
-webkit-animation-delay: -1.5s;
animation-delay: -1.5s;
}

.box:nth-child(3) {
-webkit-animation-delay: -2.25s;
animation-delay: -2.25s;
}

.box:before,
.box:after {
display: block;
content: "";
position: absolute;
width: 30px;
height: 30px;
}

.box:before {
top: 100%;
left: 0;
background: #8c8c8c;
-webkit-transform-origin: center top;
-ms-transform-origin: center top;
transform-origin: center top;
-webkit-transform: rotateX(-90deg);
transform: rotateX(-90deg);
}

.box:after {
top: 0;
left: 100%;
background: #bfbfbf;
-webkit-transform-origin: center left;
-ms-transform-origin: center left;
transform-origin: center left;
-webkit-transform: rotateY(90deg);
transform: rotateY(90deg);
}

@-webkit-keyframes move {
0%,
100% {
-webkit-transform: none;
transform: none;
}

12.5% {
-webkit-transform: translate(30px, 0);
transform: translate(30px, 0);
}

25% {
-webkit-transform: translate(60px, 0);
transform: translate(60px, 0);
}

37.5% {
-webkit-transform: translate(60px, 30px);
transform: translate(60px, 30px);
}

50% {
-webkit-transform: translate(60px, 60px);
transform: translate(60px, 60px);
}

62.5% {
-webkit-transform: translate(30px, 60px);
transform: translate(30px, 60px);
}

75% {
-webkit-transform: translate(0, 60px);
transform: translate(0, 60px);
}

87.5% {
-webkit-transform: translate(0, 30px);
transform: translate(0, 30px);
}
}

@keyframes move {
0%,
100% {
-webkit-transform: none;
transform: none;
}

12.5% {
-webkit-transform: translate(30px, 0);
transform: translate(30px, 0);
}

25% {
-webkit-transform: translate(60px, 0);
transform: translate(60px, 0);
}

37.5% {
-webkit-transform: translate(60px, 30px);
transform: translate(60px, 30px);
}

50% {
-webkit-transform: translate(60px, 60px);
transform: translate(60px, 60px);
}

62.5% {
-webkit-transform: translate(30px, 60px);
transform: translate(30px, 60px);
}

75% {
-webkit-transform: translate(0, 60px);
transform: translate(0, 60px);
}

87.5% {
-webkit-transform: translate(0, 30px);
transform: translate(0, 30px);
}
}

.boxcontainer {
width: 100%;
height: 100vh;
background-color: #4d4d4d;
// background-color: #e6e6e6;
// opacity: 0.8;
// / filter: blur(20%); /
}

.displayloader{
    position: fixed;
    top: -500px;
    left: 0;
    width: 100%;
    height: 500vh;
    background-color: rgba(20, 20, 20, .9);
    z-index: 1200;
    display: flex;
    /* justify-content: center;
    align-items: center;
    transform: scale(0); */
   
  }