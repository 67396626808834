.small{
    display: none
}
.about-small{
    display: none;
}
.small-img{
    width: 550px;
    height: 450px;
    display: flex;
    margin-bottom: 30px;
    /* width: "550px" !important;
    height: "550px" !important; */
}
/* .small-img img{
    max-width: "550px" !important;
    max-height: "550px" !important;
    width: "550px" !important;
    height: "550px" !important;
    cursor: "pointer"
} */


@media only screen and (max-width: 1040px) {
    .small{
        display: block;
    }
    .about{
        display: none;
    }
    .about-small{
        display: block;
    }
    .small-preview{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

}
@media only screen and (max-width: 590px) {
    .small-img{
        max-width: 100%;
        height: 70%;
    }
    .small-control{
        width: 100% !important;
    }
}
