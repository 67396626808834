@import "../../styles/helpers";

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 72px auto 32px;
    border-radius: 50%;
    background: $purple;
    svg {
        fill: $neutrals8; } }

.info {
    margin-bottom: 32px;
    text-align: center;
    @include body-bold-2; }

.button {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }
