@import "../../styles/helpers";

.title {
    margin-bottom: 32px; }

.text {
    margin-bottom: 32px;
    @include body-2;
    color: $neutrals4; }

.info {
    margin-bottom: 3px;
    @include body-bold-1; }

.input {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid $neutrals6;
    @include poppins;
    @include body-bold-2;
    color: $neutrals2;
    @include placeholder {
        color: $neutrals4; } }

.btns {
    margin-top: 32px; }

.button {
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }
