
@import "../../styles/helpers";

.container {
    margin-top: 50px; }

.hero_box {
    padding: 20px 0;
    background: #f9f8f6; }

.hero_Container_Home {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    min-height: 500px;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    .left-box {
        flex-basis: 40%;
        display: flex;
        flex-grow: 1;
        min-height: 200px;
        min-width: 300px; }
    .right-box {
        flex-basis: 40%;
        flex-grow: 1;
        .ant-spin-nested-loading {
            width: 90%; }
        .collection-box {
            cursor: pointer;
            border-radius: 10px;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            margin-left: auto;
            max-width: 600px;
            min-width: 346px;
            .ant-card-body {
                padding: 0 !important;
                .collection-img {
                    position: relative;
                    border-radius: 10px 10px 0 0;
                    width: 100%; } }
            @media only screen and (max-width: 480px ) {
                min-width: 90%;
                margin: 0 auto; } } } }

.heading {
    border-radius: 10PX;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center; }



.middleContainer {
    min-height: 400px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    padding: 30px;
    flex-wrap: wrap;
    justify-content: center;
    background-size: cover;
    width: 90%;
    margin: 0 auto 50px auto;
    border-radius: 10px; }

.featured-heading {
    color: #007bff;
    padding: 10px;
    text-align: center;
    margin-top: 20px; }

.featuredCard {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 10px;
    color: #3772FF;
    display: flex;
    margin: 20px;
    min-width: 280px;
    max-width: 350px;
    min-height: 330px;
    flex-grow: 1;
    background: #fff;
    padding: 0 !important;
    &:hover {
        transform: translateY(-10px); }
    .img-container {
        height: 300px; }
    .ant-card {
        border-radius: 10px !important;
        height: 100%;
        .ant-card-body {
            padding: 0 !important;
            height: 100%; }
        .img-box {
            display: flex;
            justify-content: center;
            position: relative;
            border-radius: 10px;
            height: 100%;
            .img {
                background: #fff;
                border-radius: 10px;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: relative;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; } } } }

    .content {
        height: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 20px 10px 20px; } }

.priceCard-eth-img {
  max-width: 25px !important; }
