@import "../../../styles/helpers";

.item {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        margin-bottom: 16px; } }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.copy {
    margin-left: 8px;
    svg {
        fill: $blue;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: darken($blue, 10); } } }

.reward {
    position: absolute;
    right: -5px;
    bottom: -5px;
    img {
        max-width: 25px; } }

.details {
    flex-grow: 1; }

.position {
    color: $neutrals4; }

.name {
    font-weight: 500; }

