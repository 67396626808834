@import "../../styles/helpers";

.header {
    position: relative;
    z-index: 20;
    padding: 20px 0;
    border-bottom: 1px solid $neutrals6;
    @include m {
        padding: 48px 0 24px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    @media only screen and (max-width: 500px ) {
        padding: 0 10px; }
    & > .button {
        margin-right: 12px;
        @include m {
            display: none; } } }


.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    max-width: 60px;
    @media only screen and (max-width: 500px ) {
        width: 40px; }
    @include d;

    img {
        width: 100%; } }

.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    .NavLink {
        padding: 0 20px;
        color: #000;
        @include dark {
            color: #fff; }
        &:hover {
            color: #3772FF; } }
    @include d {
        flex-grow: 0; }
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        margin: 0;
        padding: 140px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }


.nav {
    display: flex;
    margin-right: auto;
    border-left: 2px solid $neutrals6;
    @include d {
        display: none; }
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.link {
    margin-left: 30px;
    @include dm-sans;
    line-height: 40px;
    color: #1890ff;
    transition: color .2s;
    font-size: clamp(0.7rem, 2.5vw, 1rem);
    font-weight: 400px !important;
    @include m {
        margin-left: 10px;
        line-height: 64px; }
    &:hover,
    &.active {
        color: $blue; }
    &:nth-child(n+3) {
        display: none;
        @include m {
            display: block; } } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.notification {
    margin-right: 18px;
    @include m {
        margin-right: 20px; } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }

.left {
    flex-basis: 60%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 830px ) {
        flex-basis: 90%; } }
.right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    flex-basis: 40%;
    .button {
        padding: 0 30px; }
    @media only screen and (max-width: 830px ) {
        flex-basis: 10%; } }


.networkInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (max-width: 830px ) {
        display: none; } }

.popover {
    display: none;
    margin-right: 5px;
    @media only screen and (max-width: 830px ) {
        display: block; } }

.walletImage {
    max-width: 25px;
    max-height: 25px;
    @media only screen and (max-width: 830px ) {
        max-width: 20px;
        max-height: 20px; } }
