@import "../../styles/helpers";

.section {
    padding: 96px 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 16px; } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%); }

.title {
    margin-bottom: 8px; }

.cost {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }

.price {
    @include dm-sans;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }
